.App {
  width: 100%;
  margin: auto;
  height: 100vh;
}

@media only screen and (min-height: 650px) {
  .App {
    overflow: hidden; 
  }  
}