.driverGridContainer {
    display: grid;
    grid-template-columns: 40% 60%;
}

.adminGridContainer {
    display: grid;
    grid-template-columns: 60% 40%;
}

.leftSide {
    grid-column-start: 1;
}

.rightSide {
    position: relative;
    grid-column-start: 2;
    height: 100vh;
}

.scrollable::-webkit-scrollbar {
    display: none;
}

.scrollable {
    overflow: scroll;
}

.buttonRowStyle {
    vertical-align: bottom;
    display: flex;
    align-items: center;
    justify-content: space-between;
}