
.spinnerContainer {
    background-color:rgba(171, 183, 183, .4);
    position: absolute;
    z-index: 9;
    height: 90vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    width: 50px;
    height: 50px;
    animation: spin 3s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }



